import React from "react"
import Layout from "../components/layout/layout"
import Header from "../components/home/header"
import SEO from "../components/seo"
import Image from "../images/video.png"
import Happens from "../components/home/happens"
import Brand from "../components/home/brand"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords="hhs" image={Image} />
    <Header />
    <Happens />
    <Brand />
  </Layout>
)

export default IndexPage
