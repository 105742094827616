import React, { useState } from "react"
import { Container, Modal } from "reactstrap"
import MyButton from "../button"
import YouTube from "react-youtube"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ className }) => {
  //hooks
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  //react youtube
  const opts = {
    height: "505",
    width: "100%",

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  return (
    <header className="header bg1 position-relative">
      <Container>
        <div className="row flex">
          <div className="header-t col-12 col-lg-6">
            <h3>HUNGRY HIPPO SOLUTIONS</h3>
            <h1 className="">
              We Help Restaurants <b>Increase Sales</b>, While Helping Them
              Shift Their Dependence on Third Party Providers.
            </h1>

            <p>
              The recipe for increased sales is a lot like a great burger. You
              just need the right combination of ingredients and skills.
              Fortunately for you we have that recipe and are willing to provide
              it to every restaurant owner who is willing to listen.
            </p>

            <MyButton url="service" name="Get Started" />
          </div>
          <div className="video col-12 col-lg-6">
            <div className="video-play">
              <div onClick={toggle}>
                <div className="show-image" key="about-image" inverse>
                  <StaticImage
                    src="../../images/video.png"
                    alt="Video"
                    placeholder="blurred"
                  />
                </div>
              </div>
              <Modal
                size="lg"
                isOpen={modal}
                toggle={toggle}
                className={className}
              >
                <span
                  className="close-gallery"
                  color="secondary"
                  onClick={toggle}
                ></span>
                <YouTube
                  showRelatedVideos={false}
                  videoId="pzqgvNI13Xk"
                  opts={opts}
                  autoplay
                />
              </Modal>
            </div>
          </div>
        </div>
      </Container>
      <div className="gradient"></div>
    </header>
  )
}

export default Header
