import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Card from "./card"
import MyButton from "../button"

const Happens = () => (
  <div className="main-content">
    <div className="container">
      <div className="text-content">
        {/* <span>What we do</span> */}

        <h1>
          Helping family owned restaurants increase sales without additional
          work.
        </h1>

        <p>How it happens:</p>
      </div>
      <div className="card-wrapper">
        <Card
          title="Website"
          description="The process starts by modernizing your existing website. A solid foundation is the base for sustainable growth. The main outcome of modernizing your website is speed, cause speed results in more visitors."
        >
          <StaticImage
            src="../../images/home/howithappens/internet.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
        <Card
          title="Ordering"
          description="Simplicity is key. Brining your online ordering process in house results in more control. Control of the customers experience. Control over collecting your customers data. Control over providing a simple visual experience for them to select their meal."
        >
          <StaticImage
            src="../../images/home/howithappens/order-food.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
        <Card
          title="Google"
          description="Using Google’s tools to give you local customers attention is what matters most to improving sales. Our process helps put the attention where it matters. "
        >
          <StaticImage
            src="../../images/home/howithappens/google.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
        <Card
          title="Offline"
          description="We target local residents near the restaurant with direct mail. Giving them a reason to come visit you for a meal, using offline to get them to order online. "
        >
          <StaticImage
            src="../../images/home/howithappens/postcard.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
        <Card
          title="Email"
          description="Costs across the board are rising. Having and using customers emails to drive repeat orders is simple,  cost effective and measurable."
        >
          <StaticImage
            src="../../images/home/howithappens/email.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
        <Card
          title="Support"
          description="Having the support to help run your website has never been more important. We are here to help you with things like  keeping menu prices updated, change hours on Holidays and ensuring your campaigns offline and online go smoothly. "
        >
          <StaticImage
            src="../../images/home/howithappens/support.png"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            width={60}
            height={60}
          />
        </Card>
      </div>
      <div className="d-flex service-button">
        <MyButton url="service" name="Read More" />
      </div>

      <p className="text-p s-text">
        Operating a restaurant since the pandemic has been anything but easy.
        The way forward can look different than the next person’s depending on
        many factors. Regardless of your restaurants location, size and
        operations you should and can get better results from the same efforts.
        Let us help you.
      </p>
    </div>
  </div>
)

export default Happens
