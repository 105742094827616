import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Brand = () => (
  <div className="work-wrapper position-relative">
    <div className="position-absolute gradient top"></div>
    <div className="position-absolute gradient bottom"></div>
    <div className="container">
      {/* <div className="text-content">
        <span>Built with</span>
        <h1>Simplicity, Speed and Sales in Mind</h1>
      
      </div> */}
      <div className="work-card-wrapper">
        <div className="icon-wrapper">
          <h3>WHO WE HAVE HELPED</h3>
          <div className="work-icon">
            <StaticImage
              src="../../images/home/logo/FWD.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/RUSA.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={35}
            />
            <StaticImage
              src="../../images/home/logo/FL.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/BARR.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/FPF.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/logo.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/MO.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/RC.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/SF.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              height={40}
            />
            <StaticImage
              src="../../images/home/logo/TPP.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Brand
