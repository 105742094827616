import React from "react"

const Card = ({ title, description, children }) => (
  <div className="card">
    {children}
    <div className="card-text">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
)

export default Card
